import React from 'react'
import './App.css'
import { PC_S_MAX_WIDTH } from './constants/breakpoints'
import styled from 'styled-components'
import Header from './components/top/Header'
import SloganContent from './components/slogan/SloganContent'
import OurServices from './components/our-services/OurServices'
import OurProducts from './components/our-products/OurProducts'
import ContactUs from './components/contact-us/ContactUs'
import Footer from './components/bottom/Footer'

const S = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    overflow: scroll;
  `,
  HeaderContainer: styled.div`
    width: 100%;
    height: 5rem;
    flex-shrink: 0;
  `,
  FooterContainer: styled.div`
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  MainContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4rem;
  `,
  WideContainer: styled.div<{ bgColor?: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: ${({ bgColor }) => bgColor || 'black'};
  `,
  StaticContainer: styled.div`
    width: calc(100% - 4rem);
    max-width: ${PC_S_MAX_WIDTH}px;
    padding: 0 2rem 2rem 2rem;
  `,
}
function App() {
  return (
    <S.Container>
      <S.HeaderContainer>
        <Header />
      </S.HeaderContainer>

      <S.MainContainer>
        <S.StaticContainer>
          <SloganContent />
        </S.StaticContainer>

        <S.StaticContainer>
          <OurServices />
        </S.StaticContainer>

        <S.StaticContainer>
          <OurProducts />
        </S.StaticContainer>

        <S.StaticContainer>
          <ContactUs />
        </S.StaticContainer>
      </S.MainContainer>

      <S.FooterContainer>
        <S.StaticContainer>
          <Footer />
        </S.StaticContainer>
      </S.FooterContainer>
    </S.Container>
  )
}

export default App
