import React from 'react'
import styled from 'styled-components'
import TextView from '../common/TextView'
import ContentTitle from '../common/ContentTitle'
import EmailLink from "../common/EmailLink";

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `,
  ItemContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
}

function ContactUs() {
  return (
    <S.Container>
      <ContentTitle title={'Contact Us'} />

      <S.ItemContainer>
        <TextView type={"H3"} text={"낯선길 탐험대"} />
        <EmailLink type={"H3"} email={"road@explorers.kr"} />
      </S.ItemContainer>
    </S.Container>
  )
}

export default ContactUs
