import React from 'react'
import styled from 'styled-components'
import TextView from '../common/TextView'
import ContentTitle from '../common/ContentTitle'
import LinkA from "../common/LinkA";

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `,
  ItemContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
}

function OurProducts() {
  return (
    <S.Container>
      <ContentTitle title={'Our Products'} />

      <S.ItemContainer>
        <TextView type={'H3'} text={'Paperlog'} weight={800} />
        <TextView type={'B2'} text={'Canvas 기반 자유로운 블로그'} />
        <LinkA type={'B2'} link={'https://paperlog.xyz'} />
      </S.ItemContainer>

      <S.ItemContainer>
        <TextView type={'H3'} text={'Wez NFT Gallery'} weight={800} />
        <TextView type={'B2'} text={'여러 Wallet 의 NFT 를 한번에 모아서보는 APP'} />
        <LinkA type={'B2'} link={'https://we-z.xyz'} />
      </S.ItemContainer>
    </S.Container>
  )
}

export default OurProducts
