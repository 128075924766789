import React from 'react'
import styled from 'styled-components'
import TextView, { FontType } from './TextView'

const S = {
  Container: styled.a`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,
  Link: styled.a`
    width: fit-content;
    cursor: pointer;
  `,
  Image: styled.img`
    width: 1rem;
    height: 1rem;
  `,
}

interface AppProps {
  type: FontType
  link: string
  text?: string
  color?: string
  hoverColor?: string
  weight?: number
}

function LinkA({ type, text, link, color, hoverColor, weight }: AppProps) {
  return (
    <S.Container>
      <S.Link href={link} target={'_blank'}>
        <TextView
          type={type}
          text={text || link}
          color={color || 'white'}
          hoverColor={hoverColor || '#959595'}
          weight={weight || 400}
        />
      </S.Link>
    </S.Container>
  )
}

export default LinkA
