import React from 'react'
import styled from 'styled-components'
import TextView from '../common/TextView'
import ContentTitle from '../common/ContentTitle'

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `,
  ItemContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
}

function OurServices() {
  return (
    <S.Container>
      <ContentTitle title={'Our Services'} />

      <S.ItemContainer>
        <TextView type={'H3'} text={'제품 개발'} weight={800} />
        <TextView type={'B1'} text={'F/E: React.js, Next.js'} />
        <TextView type={'B1'} text={'B/E: Spring, Nest.js'} />
        <TextView type={'B1'} text={'Mobile: React Native, Android'} />
      </S.ItemContainer>

      <S.ItemContainer>
        <TextView type={'H3'} text={'기술 강의'} weight={800} />
        <TextView type={'B1'} text={'Web Programming'} />
        <TextView type={'B1'} text={'Android Programming'} />
        <TextView type={'B1'} text={'Server Programming'} />
        <TextView type={'B1'} text={'TDD / XP'} />
      </S.ItemContainer>

      <S.ItemContainer>
        <TextView type={'H3'} text={'애자일 코칭'} weight={800} />
        <TextView type={'B1'} text={'애자일 강의'} />
        <TextView type={'B1'} text={'Pair Works'} />
      </S.ItemContainer>
    </S.Container>
  )
}

export default OurServices
