import React from 'react'
import styled from 'styled-components'
import TextView from '../common/TextView'

const S = {
  Container: styled.div`
    font-weight: 900;
    padding: 4px;
    cursor: pointer;
  `,
}

function Logo() {
  return (
    <S.Container>
      <TextView type={'H2'} text={'낯선길 탐험대'} weight={800} />
    </S.Container>
  )
}

export default Logo
