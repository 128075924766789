import React from 'react'
import styled from 'styled-components'
import TextView from './TextView'

const S = {
  Container: styled.div`
    padding-bottom: 1rem;
  `,
}

interface AppProps {
  title: string
}

function ContentTitle({ title }: AppProps) {
  return (
    <S.Container>
      <TextView type={'H1'} text={title} weight={800} />
    </S.Container>
  )
}

export default ContentTitle
