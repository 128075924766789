import React from 'react'
import ImageView from '../common/ImageView'
import styled from 'styled-components'
import MainImage from '../../assets/images/main.webp'
import TextView from '../common/TextView'
const S = {
  Container: styled.div`
    position: relative;
    width: 100%;
    height: 45rem;
  `,
  TextContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    padding: 2rem;
  `,
}
function SloganContent() {
  return (
    <S.Container>
      <ImageView src={MainImage} />
      <S.TextContainer>
        <TextView type={'H0'} text={'Explore the world'} color={"#FFC95F"} weight={900} />
        <TextView type={'B1'} text={'We dream of a world where technology helps solves people\'s problems.'} color={"#FFF9C9"} />
      </S.TextContainer>
    </S.Container>
  )
}

export default SloganContent
