import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'

const S = {
  Container: styled.div`
    width: calc(100% - 4rem);
    height: 100%;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}
function Header() {
  return (
    <S.Container>
      <Logo />
    </S.Container>
  )
}

export default Header
