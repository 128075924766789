import React from 'react'
import styled from 'styled-components'
import TextView, { FontType } from './TextView'

const S = {
  Container: styled.a`
    width: fit-content;
  `,
}

interface AppProps {
  type: FontType
  email: string
}

function EmailLink({ type, email }: AppProps) {
  return (
    <S.Container href={`mailto:${email}`}>
      <TextView type={type} text={email} hoverColor={'#959595'} />
    </S.Container>
  )
}

export default EmailLink
