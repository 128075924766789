import React from 'react'
import styled from 'styled-components'
import TextView from "../common/TextView";

const S = {
  Container: styled.div`
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid #959595;
  `,
}
function Footer() {
  return (
    <S.Container>
      <TextView type={"B2"} text={"상호명: 낯선길 탐험대(Explorers)"} />
      <TextView type={"B2"} text={"대표명: 김기훈"} />
      <TextView type={"B2"} text={"사업자등록번호: 540-18-02194"} />

      <TextView type={"B2"} text={"대표 메일: road@explorers.kr"} />
    </S.Container>
  )
}

export default Footer
