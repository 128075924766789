import React from 'react'
import styled from 'styled-components'
import { PC_S_MAX_WIDTH } from '../../constants/breakpoints'

const S = {
  Image: styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;

    @media (min-width: ${PC_S_MAX_WIDTH}px) {
      object-fit: fill;
    }
  `,
}

interface AppProps {
  src: string
}

function ImageView({ src }: AppProps) {
  return <S.Image src={src} />
}

export default ImageView
